import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';
import { updateVar } from '../apollo/reactive-variables';
import { GET_USER_TOKEN } from '../graphql';

const useGetUserToken = (tokenMinutes = 15) => {
  const [userId, setUserId] = useState('');
  const client = useApolloClient();
  const mutationResult = useMutation(GET_USER_TOKEN, {
    variables: {
      userId,
      tokenMinutes,
    },
    onCompleted: ({ getUserToken: { token } }) => {
      setUserId('');
      updateVar('token', token);
      client.cache.reset();
    },
  });
  return [userId, setUserId, mutationResult];
};

export default useGetUserToken;
