import moment from 'moment';
import { useMemo } from 'react';
import { FingoChristmas, FingoLogo, FingoChile } from '../assets';

const useDynamicFingoLogo = () => useMemo(
  () => ({
    9: FingoChile,
    12: FingoChristmas,
  }[moment().format('M')] || FingoLogo),
  [],
);
export default useDynamicFingoLogo;
