import { useCallback, useState } from 'react';

const useTextFieldInput = (initialValue = '') => {
  const [input, _setInput] = useState(initialValue);
  const setInput = useCallback((event) => {
    _setInput(event.target.value);
  }, []);
  const resetInput = useCallback(() => {
    _setInput('');
  }, []);
  return [input, setInput, resetInput, _setInput];
};

export default useTextFieldInput;
