import { useQuery, useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '../../apollo/reactive-variables';
import { OFFER_SHOPPING_CART } from '../../graphql';

const useOfferShoppingCart = () => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const updatedShoppingCartOffers = shoppingCartOffers.map((offer) => {
    if (offer.dateToPay) {
      return { ...offer, dateToPay: offer.dateToPay ? offer.dateToPay.format('YYYY-MM-DD') : null };
    }
    return offer;
  });
  const { data: shoppingCartData, loading } = useQuery(
    OFFER_SHOPPING_CART,
    { variables: { offerSelected: updatedShoppingCartOffers } },
  );

  const disabledShowCartFunction = () => (
    shoppingCartOffers.length === 0
    || shoppingCartOffers.filter((off) => off.dateToPay === null).length > 0
  );

  const disabledShowCart = disabledShowCartFunction();
  const thisShoppingCartData = shoppingCartData?.offerShoppingCart;

  return { shoppingCartData: thisShoppingCartData, disabledShowCart, loading };
};

export default useOfferShoppingCart;
