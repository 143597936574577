import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { MY_ASSIGNED_COMPANIES } from '../graphql/user';
import useIsLogged from './useIsLogged';
import useDebounce from './useDebounce';
import useGetCountryFromUrl from './useGetCountryFromUrl';

const useGetMyCompanies = (
  {
    after,
    first,
    masterEntityNameIcontains,
  },
  skip = false,
) => {
  const isLogged = useIsLogged();
  const country = useGetCountryFromUrl();
  const [search, setSearch] = useState(masterEntityNameIcontains);
  const debounce = useDebounce(setSearch, 800);
  useEffect(() => {
    debounce(masterEntityNameIcontains);
  }, [masterEntityNameIcontains]);
  const { data, previousData, ...rest } = useQuery(MY_ASSIGNED_COMPANIES, {
    variables: {
      orderBy: 'masterEntity_Name',
      after,
      first,
      masterEntity_Name_Icontains: search,
      masterEntity_CountryId: country?.id,
    },
    skip: !isLogged || skip,
    notifyOnNetworkStatusChange: true,
  });
  const usableData = data || previousData;
  const companies = usableData?.myCompanies.edges.map((edge) => edge.node) || [];
  const totalCompanies = data?.myCompanies.totalCount;
  return { data, companies, totalCompanies, ...rest };
};

export default useGetMyCompanies;
