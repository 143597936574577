import { useCallback, useState } from 'react';

const useAnchorElementState = (value) => {
  const [anchor, setAnchor] = useState(value);
  const openAnchor = useCallback((buttonEvent) => {
    setAnchor(buttonEvent.currentTarget);
  }, []);
  const closeAnchor = useCallback(() => {
    setAnchor(null);
  }, []);
  return [anchor, openAnchor, closeAnchor];
};

export default useAnchorElementState;
